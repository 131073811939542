import {
    Axios
} from "@/shared/axios"
import Plan from "../../models/Plan";
/**
 * A Service to handle API calls for Plan entity.
 * @class
 */
export default class PlanService {
    /**
     * @typedef {import('../../models/Plan').default} Plan
     */

    constructor() {

    }

    /**
     * Retrieves an All HMO Plana.
     *
     * @return {Array <Plan>} A collection of Plan objects
     *
     * @example
     *
     *     await getPlans()
     */
    getPlans = async () => {
        try {
            return await Axios.get(`hmo-client/hmo-plans`);
        } catch (error) {
            return {
                //This is very wrong. Not all errors are 500. Will refactor
                message: "Error Occured",
                status: 500
            }
        }
    }
}